<template>
	<div>
		<ProductFamilyForm
			:product-family="productFamily"
		/>
	</div>
</template>

<script>
import ProductFamilyForm from '../components/ProductFamilyForm.vue';

export default {
	name: 'ProductFamilyCreate',

	components: {
		ProductFamilyForm,
	},

	data() {
		return {
			productFamily: {},
		};
	},
};
</script>
